<div class="development">
  <h3 class="development__title">Development Team</h3>
  <p class="development__description">
    The Technology Policy Recommendations database is a product of the <a
      class="development__link"
      href="https://www.csis.org/programs/dracopoulos-ideas-lab"
      target="_blank"
      rel="noreferrer">Andreas C. Dracopoulos iDeas Lab</a
    >, the in-house digital, multimedia, and design agency at the Center for
    Strategic and International Studies.
  </p>
  <p class="development__description">
    Produced by Christina Hamm, William Taylor & José Romero.
  </p>
</div>

<style lang="scss">
  @use '../scss/components/development-team';
</style>
